<template>
  <figure :class="{ 'bg-cover bg-center aspect-video': fulfillBackground }">
    <img :src="url" :alt="alt || 'Zástupný obrázok'" class="w-full h-full" :class="[imageClass, { 'backdrop-blur-3xl': fulfillBackground }]" />
    <slot></slot>
  </figure>
</template>
<script setup lang="ts">
const fixUrl = useFixUrl()
const page = usePage()
const props = defineProps({
  src: {
    type: String,
  },
  alt: {
    type: String,
    required: false,
  },
  imageClass: {
    type: String,
    required: false,
  },
  fulfillBackground: {
    type: Boolean,
    default: false,
  },
})

const url = computed(() => fixUrl(props.src) || fixUrl(page.value.fallbackImage?.data?.attributes?.url))
</script>
